import {
  GetStaticPropsContextWithUserState,
  withStaticProps,
} from '~/lib/static';
import { GetStaticPaths, GetStaticPropsResult } from 'next';

import { getCommentsV2, getDiscussionsUrlV2 } from '~/lib/discussions/fetch';
import { fetchJson } from '~/lib/fetch';
import { isTidyCal } from '~/lib/util/helpers';
import { ProductPageComponent } from '~/components/product/ProductPageComponent';
import { Plan } from '~/types/deal';
import { IParams, ProductPageProps } from '~/types/product';
import { DEAL_COMMENT_TYPE } from '~/lib/discussions/constants';

export const getStaticProps = withStaticProps(
  async ({ params, userState }: GetStaticPropsContextWithUserState) => {
    const { slug } = params as IParams;

    let details = null;
    try {
      details = await fetchJson(
        `/api/v2/deals/${slug}/details/?include[]=taxonomy&include[]=reviews_summary&is_staff=${
          userState?.is_staff ?? ''
        }`,
      );
    } catch (error: any) {
      if (error?.response?.headers.get('X-Appsumo-Rewrite') === 'true') {
        return {
          redirect: {
            destination: `/products/${slug}/?p=1`,
          },
        } as GetStaticPropsResult<ProductPageProps>;
      }

      return {
        notFound: true,
      };
    }

    const { deal } = details;
    if (!deal) {
      return {
        notFound: true,
      };
    }

    if (isTidyCal(deal.slug) && deal.plans) {
      deal.plans = deal.plans.filter((plan: Plan) => plan.tier !== 0);
    }

    const questions = await getCommentsV2(
      getDiscussionsUrlV2({
        dealId: deal.id,
        type: DEAL_COMMENT_TYPE.QUESTION,
        sort: 'latest',
      }),
    );

    const reviews = await getCommentsV2(
      getDiscussionsUrlV2({ dealId: deal.id, type: DEAL_COMMENT_TYPE.REVIEW }),
    );

    return {
      props: {
        deal,
        questions,
        reviews,
      },
      revalidate: 240,
    };
  },
);

export const getStaticPaths: GetStaticPaths = () => {
  return {
    paths: [],
    fallback: 'blocking',
  };
};

/**
 * PDP deal page.
 */
const ProductPage = (props: ProductPageProps) => {
  return <ProductPageComponent {...props} />;
};

export default ProductPage;
